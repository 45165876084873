


import ContentCard from 'portal/components/common/content-card/ContentCard.vue';
import ContentBlockDescription
    from 'portal/components/common/content-card/ContentCardDescription.vue';
import VImage from '@@/common/components/ui/images/VImage.vue';


export default {
    name: 'OneToTwo',
    components: { VImage, ContentBlockDescription, ContentCard },

    props: {
        bigCard: {
            type: Object,
            required: true,
            default: () => ({}),
        },

        smallCards: {
            type: Array,
            required: true,
            default: () => [],
        },

        smallMediasTitle: {
            type: String,
            default: null,
        },

        firstScreenBlock: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            imgWidth: 800,
            imgSizes: 'mobile:100vw tablet:800px laptop:800px desktop:800px',
        };
    },

    computed: {
        hasBigCard() {
            return Object.keys(this.bigCard).length > 0;
        },

        altString() {
            return `${this.bigCard?.title.replace(/<\/?[^>]+(>|$)/g, '')}_${this.bigCard?.id}`;
        },
    },
};
